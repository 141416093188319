import { Component, OnInit} from '@angular/core';



@Component({
  selector: 'app-oac-dashboard',
  templateUrl: './oac-dashboard.component.html',
  styleUrls: ['./oac-dashboard.component.css']
})
export class OacDashboardComponent implements OnInit {

  selectedDashboard: Number;
 
  constructor() {
    
   }

  ngOnInit() {
    this.selectedDashboard = 1;
  }


  switchDashboard(dashboardID: Number) {
    console.log('[switchDashboard] ', dashboardID);
    this.selectedDashboard = dashboardID;
    
  
  }
  embedUrl() {
    return  `./embed.html?dashboardID=${this.selectedDashboard}`;
  }
}
