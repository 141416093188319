import { Component, OnInit, Input } from '@angular/core';


declare interface Header {
  label: string;
  name: string;
}

declare interface TableData {
  title: string;
  subtitle: string;
  headerColor: string;
  headers: Header[];
  rows: any[];
  // icon: string;
  // class: string;
  // visible: boolean;
}

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.css']
})
export class DataTableComponent implements OnInit {
  @Input() data: TableData;
 

  constructor() { }

  ngOnInit(): void {

  }

}
