import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OacDashboardComponent } from './oac-dashboard.component';
import { SafePipe } from '../safe.pipe';
@NgModule({
  declarations: [ OacDashboardComponent, SafePipe ],
  exports: [ OacDashboardComponent ],
  imports: [ CommonModule ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class OacDashboardModule {}