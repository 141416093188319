import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-data-card',
  templateUrl: './data-card.component.html',
  styleUrls: ['./data-card.component.css']
})
export class DataCardComponent implements OnInit {
  @Input() cardType: string;
  @Input() cardIcon: string;
  @Input() cardCategory: string;
  @Input() cardTitle: string;   
  @Input() footerIcon: string; 
  @Input() footerData: string; 

  constructor() { }

  ngOnInit(): void {

  }

}
